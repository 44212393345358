import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AutoLogoutComponent } from './auth/auto-logout/auto-logout.component';
import { LoginComponent } from './auth/login/login.component';
import { CasePreviewComponent } from './views/case-preview/case-preview.component';
import { CaseComponent } from './views/case/case.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { DiagnosticsViewComponent } from './views/diagnostics/diagnostics.component';
import { VisitsViewComponent } from './views/visits/visits.component';
import { ShareComponent } from './views/share/share.component';
import { InvitationComponent } from './views/invitation/invitation.component';
import { SavedCasesComponent } from './views/saved-cases/saved-cases.component';
import { NewCaseComponent } from './components/new-case/new-case.component';
import { LayoutSidenavContainerComponent } from './components/layout/layout-sidenav-container/layout-sidenav-container.component';
import { LayoutSidenavCaseComponent } from './components/layout-sidenav-case/layout-sidenav-case.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { VersionHistoryComponent } from './components/version-history/version-history.component';
import { SettingsComponent } from './components/settings/settings.component';
import { V3Component } from './version3/v3/v3.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'callback', component: CallbackComponent },
  { path: 'sign-up', component: SignUpComponent, pathMatch: 'full' },
  {
    path: 'dashboard',
    component: LayoutSidenavContainerComponent,
    data: { viewName: 'Dashboard' },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        pathMatch: 'full',
      },
    ]
  },
  {
    path: 'profile',
    component: LayoutSidenavContainerComponent,
    data: { viewName: 'My Profile' },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: UserProfileComponent,
        pathMatch: 'full',
      }
    ]
  },
  {
    path: 'saved-cases',
    component: LayoutSidenavContainerComponent,
    pathMatch: 'full',
    data: { viewName: 'Favorites' },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: SavedCasesComponent,
        pathMatch: 'full',
      }
    ]
  },
  {
    path: 'reports/new',
    component: LayoutSidenavContainerComponent,
    data: { viewName: 'New Case' },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: NewCaseComponent,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'reports/:id',
    canActivate: [AuthGuard],
    component: LayoutSidenavCaseComponent,
    children: [
      {
        path: '',
        component: CaseComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'visits',
        component: VisitsViewComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'diagnostics',
        component: DiagnosticsViewComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'versions',
        component: VersionHistoryComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: { isWideView: true },
      },
      {
        path: 'preview',
        component: CasePreviewComponent,
        pathMatch: 'full',
        data: { previewOn: true },
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'v3',
    component: V3Component,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'invitation/:id',
    component: InvitationComponent,
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: AutoLogoutComponent
  },
  {
    path: 'share/:id',
    component: LayoutSidenavContainerComponent,
    data: { showToolbar: false },
    children: [
      {
        path: '',
        component: ShareComponent,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'public/:id',
    redirectTo: 'share/:id',
  },
  {
    path: 'pagenotfound',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/pagenotfound',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
