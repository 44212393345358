<div class="container">
  <button mat-button (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>&nbsp;{{'NAVIGATION.BACK' | translate}}
  </button>

  <ng-container *ngIf="report">
    <h3 class="mat-title">{{"NAVIGATION.CASE SETTINGS" | translate}}</h3>
    <mat-divider></mat-divider>

    <div class="row">
      <h3 class="mat-headline">Migrate to Version 3</h3>
      <app-message-info content="Migrate this case report to Version 3. Download this report as a json file and upload it to Version 3. Need help?" learnMoreLink="/v3"></app-message-info>
      <button class="btn-migrate" (click)="openDialog()" mat-flat-button color="accent"><mat-icon>download</mat-icon>&nbsp;Download Case Report</button>
    </div>

    <mat-divider></mat-divider>
    
    <div class="row">
      <app-translator [report]="report"></app-translator>
    </div>
    <mat-divider></mat-divider>
    <div class="row">
      <h4 class="mat-subtitle-2">{{"MESSAGES.DELETE REPORT" | translate}}</h4>
      <app-delete-case-report [reportID]="report.id" [title]="report.title"></app-delete-case-report>
    </div>
  </ng-container>
</div>