<mat-toolbar class="main-toolbar">

  <div class="toolbar-container">
    <div class="inline">
      <ng-content></ng-content>
      <div class="logo" *ngIf="!(isHandset$ | async)">
        <img src="assets/logo-dark.svg" [routerLink]="['/dashboard']" alt="Care-writer">
      </div>
    </div>

    <ng-container *ngIf="(isAuthenticated$ | async) === true">
      <div class="tool-right">
        <button mat-flat-button class="btn-v3" color="accent" routerLink="/v3"><mat-icon iconPositionEnd>rocket_launch</mat-icon> Version 3</button>

        <app-lang-preference></app-lang-preference>&nbsp;
        <app-notifications></app-notifications>
        <button mat-icon-button matTooltip="{{'NAVIGATION.MY PROFILE' | translate}}" [matMenuTriggerFor]="menu"
          aria-label="Profile menu">
          <mat-icon appBtnWhite>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">

          <button mat-menu-item [routerLink]="['/profile']">
            <mat-icon>account_circle</mat-icon>
            <span>{{'NAVIGATION.MY PROFILE' | translate }}</span>
          </button>
          <button mat-menu-item [routerLink]="['/logout']" (click)="sendEvent()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ 'ACTIONS.LOG OUT' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </ng-container>
  </div>

</mat-toolbar>