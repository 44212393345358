import { Injectable } from '@angular/core';
import { Attachment, GetReportPdfGQL, Report } from '../api/generated/graphql';
import { map, Observable, of } from 'rxjs';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class MigratorService {

  constructor(private reportGQL: GetReportPdfGQL) {

  }

  downloadCaseReport(reportID: string): Observable<any> {
    if (!reportID) {
      return of(null);
    }

    return this.reportGQL.fetch({
      id: reportID
    }).pipe(
      map(res => {
        this.processData(reportID, res.data.report as Report);
        return res.data.report;
      })
    )
  }


  processData(id: string, caseReport: Report) {
    const attachments = this.processAttachments(caseReport.attachments);

    let obj = <Report>{
      __typename: caseReport.__typename,
      id: caseReport.id,
      title: caseReport.title,
      authors: caseReport.authors,
      keywords: caseReport.keywords,
      corrAuthor: caseReport.corrAuthor,
      sections: caseReport.sections,
      visits: caseReport.visits,
      diagnostics: caseReport.diagnostics,
      attachments: attachments,
    }

    // Convert the case report data to a JSON string
    const jsonData = JSON.stringify(obj, null, 2);

    // Create a Blob with the JSON data
    const blob = new Blob([jsonData], { type: 'application/json' });

    // Save the file using FileSaver.js
    saveAs(blob, `case-report-${id}.json`);
  }

  processAttachments(attachments: Attachment[]): Attachment[] {
    return attachments.map(att => {
      let obj = <Attachment>{
        id: att.id,
        description: att.description,
        fileName: att.fileName,
        fileType: att.fileType,
        order: att.order,
        storageName: att.storageName,
      }

      return obj;
    })
  }
}
