<div class="container">
    <button class="home" mat-stroked-button routerLink="/dashboard"><mat-icon inline="true">arrow_back</mat-icon> Home</button>
    <h1>🚀 CARE-writer Version 3 is Coming Soon!</h1>

    <mat-card>
        <mat-card-header>
            <mat-card-title>We're excited to introduce a new and improved CARE-writer!</mat-card-title>
            <mat-card-subtitle>Version 3 brings powerful new features, enhanced collaboration, and a streamlined writing experience.</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <p>We’ve listened to our community and worked hard to build a better platform for writing and managing medical case reports.</p>

            <h3>🌟 What's New in Version 3?</h3>
            <ul>
                <li>⚡ Enhanced real-time collaboration</li>
                <li>📝 Improved formatting and editing tools</li>
                <li>📂 A more intuitive case report workflow</li>
                <li>🔄 Seamless document versioning and updates</li>
            </ul>

            <h3>🚀 Try Version 3 Now</h3>
            <p>CARE-writer Version 3 is currently in limited beta. You can start using the new platform today! <br>
                <a class="care-link" href="https://v3.care-writer.com/features">👉 Click here to access Version 3</a>
            </p>

            <h3>📢 Important Notice</h3>
            <p>CARE-writer Version 3 is built on a new platform with a separate database. Your existing case reports from the current version will not automatically transfer. We are working on a migration feature. Stay tuned for updates!</p>

            <h3>🔄 What You Need to Do</h3>
            <p>The current version of CARE-writer will remain accessible until later in 2025. Before we retire the current platform, you will have the option to migrate your existing case reports to Version 3.</p>

            <h3>📅 Stay Updated</h3>
            <p>We’ll be sharing more details soon. Keep an eye out for migration instructions and exciting new features.</p>

            <p>Thank you for being part of the CARE-writer community. We can't wait for you to experience Version 3!</p>
        </mat-card-content>
    </mat-card>
</div>