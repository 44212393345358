<div mat-dialog-content>
    <h1>Migrating Your Case Report to Version 3</h1>
    <p class="mat-body">
        To help you transition to Version 3 of the CARE-writer platform, we’ve made it easy to migrate your case report.
    </p>
    
    <h3>How does it work?</h3>
    <ol>
        <li>Click <strong>Download Case Report</strong> to save a file containing your case report data.</li>
        <li>Log in to <a href="https://v3.care-writer.com">CARE-writer Version 3</a> using the same credentials as this version.</li>
        <li>In Version 3, click <strong>Import Case Report</strong> from the left-side menu and upload the JSON file you just downloaded.</li>
    </ol>
    
    <p>Once the import is complete, you should see your case report on your dashboard.</p>
    
    <p>Having trouble? Email us at <a href="mailto:support@care-writer.com">{{email}}</a>.</p>
    
    <button mat-flat-button color="accent" (click)="initDownload()">
        <mat-icon>download</mat-icon>&nbsp;Download Case Report
    </button>
    &nbsp;&nbsp;
    <button mat-button mat-dialog-close>Cancel</button>
</div>