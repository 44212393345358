import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MigratorService } from 'src/app/migration/migrator.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-migrator-dialog',
  templateUrl: './migrator-dialog.component.html',
  styleUrls: ['./migrator-dialog.component.css']
})
export class MigratorDialogComponent implements OnInit {

  email: string = "support@care-writer.com";
  
  constructor(
    private migratorService: MigratorService,
    private snackService: SnackService,
    public dialogRef: MatDialogRef<MigratorDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit(): void {
  }

  initDownload(): void {
    this.migratorService.downloadCaseReport(this.data).subscribe(() => {
      this.snackService.generateSnack("Download started");
      this.dialog.closeAll();
    })
  }

}
